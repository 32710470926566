
// import { callMsGraph, logOut as apiLogout, doAjax as apiDoAjax, getFile as apiGetFile, getToken as apiGetToken, signalrHubConnection as apiSignalrHubConnection, getUserPhotoMsGraph } from '@museco/msalfunctions';
import { objExtend } from '@museco/objextend';
import { PublicClientApplication, InteractionRequiredAuthError, LogLevel, CacheLookupPolicy, EventType  } from "@azure/msal-browser";

import 'regenerator-runtime/runtime'
import { Notifications } from '@museco/notifications';
import axios from 'axios';
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito
const commonNotificationOpts = {
    theme: 'relax',
    layout: 'topRight',
    timeout: 2500,
    loseWith: ['click'],
    maxVisible: 5
}




let accountId = "";





let getEndpointInUse = function () {
    if (window.location.href.indexOf("staging.") != -1) {
        return process.env.REACT_APP_STAGING_API_URL;
    } 
    else {
        return process.env.REACT_APP_API_URL;
    }
}



// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_DIRECTORY}`,
        redirectUri: process.env.REACT_APP_REDIRECT_URI,      
        navigateToLoginRequestUrl: true   ,
        postLogoutRedirectUri: window.location.origin,
        skipAuthorityMetadataCache: true
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        // storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        storeAuthStateInCookie: isIE || isEdge || isFirefox,
        claimsBasedCachingEnabled: true
    },

    system: {
        allowNativeBroker: false,

        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        // console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        },
        windowHashTimeout: 60000,
        iframeHashTimeout: 6000,
        loadFrameTimeout: 0,
        asyncPopups: false
    },
    telemetry: {
        application: {
            appName: "Seatruck Connect",
            appVersion: "1.0.0"
        }
    }
};

export const msalInstance = new PublicClientApplication(msalConfig);


  msalInstance.initialize();

// await msalInstance.handleRedirectPromise().then(authResult=>{
    // Check if user signed in 
    // const account = msalInstance.getActiveAccount();
    // if(!account){
    //   // redirect anonymous user to login page 
    //   msalInstance.loginRedirect(request);
    // }
//   }).catch(err=>{
//     // TODO: Handle errors
//     console.log(err);
//   });
  



export const registerRequest = {
    scopes: [ `.default`],
    prompt: 'admin_consent',   
    cacheLookupPolicy: CacheLookupPolicy.Default,
    // extraScopesToConsent: [
    //      `${process.env.REACT_APP_API_CLIENT_ID}/user_impersonation`
    // ],

};

export const request = {
    scopes: [ `${process.env.REACT_APP_API_CLIENT_ID}/user_impersonation`],
    //  prompt: 'consent',
    // extraQueryParameters: { domain_hint: config.defaultDomain },
    cacheLookupPolicy: CacheLookupPolicy.Default,
    extraScopesToConsent: [
        // `${process.env.REACT_APP_API_CLIENT_ID}/user_impersonation`
    ],
};


export const graphRequest = {
    scopes: [`User.Read`, `email`, `offline_access`, `openid`, `profile`,`User.ReadWrite.All`],
     prompt: 'select_account',
    // extraQueryParameters: { domain_hint: config.defaultDomain },
    cacheLookupPolicy: CacheLookupPolicy.Default,
    extraScopesToConsent: [
        `${process.env.REACT_APP_API_CLIENT_ID}/user_impersonation`
    ],
};


const getAccountInfo = () => {
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();
    return activeAccount || accounts[0]; // Is this the correct way ? . 
};



// After initialize and handleRedirectPromise have completed, you may call any of the other APIs as you would without this feature



// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};




//used when setting a component to use authentication - only used in projects with public and private routes
// export const withAdalLoginApi = rtnWithAdalLoginApi(msalInstance, adalConfig.endpoints[config.baseApiUrl]);
//used to get the adal tokens required to login to the aad token based api
//export const refreshAccessToken = () => getToken(msalInstance, msalRequest);
export const getUserPhoto = () => getUserPhotoMsGraph(msalInstance, graphRequest);
export const getUserDetails = () => callMsGraph(msalInstance, graphRequest, graphConfig);
//used to allow the user to logout
export const logOut = () => { return apiLogout(msalInstance) };
// //used to make an ajax call
export const doAjax = (opts) => {
    return apiDoAjax(msalInstance, getEndpointInUse(), objExtend(true, commonNotificationOpts, opts), request)
};
// //used when getting a file for dl from azure - better to use the blob storage ttl links
export const getFile = (opts) => {
    return apiGetFile(msalInstance, getEndpointInUse(), objExtend(true, commonNotificationOpts, opts), request)
};


// export const signalrHubConnection = new signalR.HubConnectionBuilder()

//     .withUrl(process.env.REACT_APP_SIGNALR_HUB_URL, {
//         // skipNegotiation: true,
//         transport: signalR.HttpTransportType.WebSockets,
//         accessTokenFactory: async () => {
//             return new Promise((resolve) => {
//                 getToken(msalInstance, request).then((token) => resolve(token));
//             });
//         },
//     })
//     // .configureLogging(LogLevel.Error)
//     .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000, 45000, 60000, 75000, 90000])
//     .build();



// signalrHubConnection.onreconnecting((error) => {
//     // disableUi(true);
//     // const li = document.createElement("li");
//     console.error(`Connection lost due to error "${error}". Reconnecting.`);
//     // document.getElementById("messagesList").appendChild(li);
// });

// signalrHubConnection.onreconnected((connectionId) => {
//     // disableUi(false);
//     // const li = document.createElement("li");
//     console.info(`Connection reestablished. Connected.`);
//     // document.getElementById("messagesList").appendChild(li);
// });

// signalrHubConnection.on('Message', (message) => {
//     console.info(message);
// });


// signalrHubConnection.start()
//     .then(() => {
//         console.log(`SignalR connection success! connectionId: ${signalrHubConnection.connectionId} `);

//     })
//     .catch((error) => {
//         console.log(`SignalR connection error: ${error}`);

//     });

msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        if (account != null) {
            accountId = account.homeAccountId;
        }
        msalInstance.setActiveAccount(account);
    }
});




export const refreshAccessToken = async () => {
    await getToken(msalInstance, request).then((token) =>{ return token});
};
export const CommonMimeTypes = {
    'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'pdf': 'application/pdf'
}



export async function getToken(msalInstance, request) {


    try {
        let tokenResponse ;

        let accountObj;
       
            accountObj = msalInstance.getAllAccounts()[0];
       

        if (accountObj) {
            // console.log("[AuthService.init] User has logged in, but no tokens.");
            try {
                const accessTokenRequest = {
                    account: msalInstance.getAllAccounts()[0],
                    ...request
                };


                tokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);

                return tokenResponse.accessToken;
            } catch (error) {


                console.log(error);



                if (error.claims) {
                    request.claims = error.claims
                }
                if (error instanceof InteractionRequiredAuthError) {


                    if (error.claims) {
                        request.claims = error.claims
                    }

                    if (error.errorCode == "invalid_client") {
                        request.prompt = 'admin_consent';
                    }


                    await msalInstance.acquireTokenRedirect(request);
                } else {
                    console.error(error);
                    await msalInstance.acquireTokenRedirect(request);
                }




            }
        } else {
            console.log("[AuthService.init] No accountObject or tokenResponse present. User must now login.");
    try     
    {
        await msalInstance.loginRedirect(request);
    }catch (error) {
    }
              
        }
            
         
        
    } catch (error) {
        console.error("[AuthService.init] Failed to handleRedirectPromise()", error)
    }




};



export async function callMsGraph(msalInstance, loginRequest, graphConfig) {
    const account = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API


    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    });

    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function getUserPhotoMsGraph(msalInstance, loginRequest) {
    const account = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API


    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    });

    const graphEndpoint = "https://graph.microsoft.com/v1.0/me/photo/$value";

    const bearer = `Bearer ${response.accessToken}`;

    const res = await axios.get(graphEndpoint, {
        headers: { 'Authorization': bearer },
        responseType: 'blob'
    }).then(o => {
        const url = window.URL || window.webkitURL;
        const blobUrl = url.createObjectURL(o.data);
        return blobUrl;
    })

    return res;

}

const apiLogout = () => {

    let activeAccount = msalInstance.getActiveAccount();

    if (!activeAccount) {
        activeAccount = msalInstance.getAccountByHomeId(accountId);
    }


    const logoutRequest = {
        account: activeAccount
    }

    msalInstance.logout(logoutRequest);
};



const apiGetFile = (msalInstance, baseApiUrl, opts, apiClientId) => new Promise(
    function (resolve, reject) {
        let defaultOpts = {
            fileName: opts.fileName,
            mimeType: opts.mimeType,
            progressMessage: false,
        };

        let optsToUse = objExtend(true, defaultOpts, opts);

        let progressNotification = null;
        let progressInterval = null;
        let progressNumber = 0;
        if (optsToUse.progressMessage !== false && typeof optsToUse.progressMessage == 'string') {
            progressNotification = Notifications.progress(optsToUse.progressMessage, optsToUse);
            progressInterval = setInterval(function () {
                progressNumber++;
                if (progressNumber > 15) {
                    progressNumber = 0;
                }
                let textToShow = optsToUse.progressMessage;
                for (let x = 0; x < progressNumber; x++) {
                    textToShow += '.';
                }
                progressNotification.setText(textToShow);
            }, 200);
        }

        getToken(msalInstance, apiClientId)
            .then(function (token) {
                var fetchConfig = {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Origin': '',
                        //'Host': 'api.producthunt.com',
                        'Authorization': 'Bearer ' + token,
                    },
                    cache: "no-cache"
                };
                fetch(
                    baseApiUrl + optsToUse.url,
                    fetchConfig
                )
                    .then((response) => {

                        // Check if the request is 200
                        if (response.ok) {
                            let data = response;

                            // if the type is json return, interpret it as json
                            if (response.headers.get('Content-Type').indexOf('application/json') > -1) {

                                data = response.json()
                                    .then((response) => {
                                        var binary_string = window.atob(response);
                                        var len = binary_string.length;
                                        var bytes = new Uint8Array(len);
                                        for (var i = 0; i < len; i++) {
                                            bytes[i] = binary_string.charCodeAt(i);
                                        }
                                        var blob = new Blob([bytes], { type: optsToUse.mimeType });

                                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                            window.navigator.msSaveOrOpenBlob(blob);
                                        } else {
                                            var objectUrl = URL.createObjectURL(blob);
                                            const link = document.createElement('a');
                                            link.href = objectUrl;
                                            link.setAttribute('download', optsToUse.fileName);
                                            document.body.appendChild(link);
                                            link.click();
                                            link.parentNode.removeChild(link);
                                            console.log('done file');

                                            resolve(true);
                                        }
                                    })
                                    .catch(() => {
                                        reject('error downloading file');
                                    });
                            }
                            //return data;
                        } else {
                            reject('error getting details for file');
                        }
                        // if an errors, anything but 200 then reject with the actuall response

                    })
                    .catch(() => {
                        reject('error with ajax request');
                    })
                    .finally(() => {
                        console.log('finally executed');
                        if (optsToUse.progressMessage !== false && typeof optsToUse.progressMessage == 'string') {
                            clearInterval(progressInterval);
                            progressNotification.close();
                        }
                    });

            })
            .catch(() => {

                reject('error getting token');
            });
    }
);

export const apiDoAjax = (msalInstance, baseApiUrl, opts, apiClientId) => new Promise(
    function (resolve, reject) {

        let defaultOpts = {
            method: 'GET',
            url: '',
            beforeNotification: false,
            progressMessage: false,
            successNotification: false,
            httpErrorNotification: true,
            errorNotification: true,
            data: {},
            cache: 'no-store',
            isFileData: false,
            authenticate: true
        };

        let optsToUse = objExtend(true, defaultOpts, opts);


        if (optsToUse.beforeNotification !== false && typeof optsToUse.beforeNotification === 'string') {
            Notifications.info(optsToUse.beforeNotification, optsToUse);
        }
        let progressNotification = null;
        let progressInterval = null;
        let progressNumber = 0;
        if (optsToUse.progressMessage !== false && typeof optsToUse.progressMessage == 'string') {
            progressNotification = Notifications.progress(optsToUse.progressMessage, optsToUse);
            progressInterval = setInterval(function () {
                progressNumber++;
                if (progressNumber > 15) {
                    progressNumber = 0;
                }
                let textToShow = optsToUse.progressMessage;
                for (let x = 0; x < progressNumber; x++) {
                    textToShow += '.';
                }
                progressNotification.setText(textToShow);
            }, 200);
        }


        if (optsToUse.authenticate) {
            getToken(msalInstance, apiClientId)
                .then(function (token) {

                    var obj;
                    if (optsToUse.isFileData) {
                        obj = getTokenHttpForFileDataObj(optsToUse, token);
                    } else {
                        obj = getTokenHttpObj(optsToUse, token);
                    }

                    fetchApi(baseApiUrl, optsToUse, obj, reject, resolve, progressInterval, progressNotification)

                })
                .catch(function (err) {
                    errorNotification(optsToUse, err, reject);
                });
        } else {
            var obj = getHttpObj(optsToUse);

            fetchApi(baseApiUrl, optsToUse, obj, reject, resolve, progressInterval, progressNotification);
        }
    }
);




function errorNotification(optsToUse, err, reject) {
    if (optsToUse.errorNotification === true) {
        Notifications.error('Fetch Error :' + err, optsToUse);
    }
    else if (optsToUse.errorNotification !== false && optsToUse.errorNotification !== true) {
        Notifications.error(optsToUse.errorNotification, optsToUse);
    }
    //might be better to do a common response here showing an error
    console.log(err);
    reject(err);
}

function getTokenHttpObj(optsToUse, token) {
    return {
        method: optsToUse.method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Origin': '',
            //'Host': 'api.producthunt.com',
            'Authorization': 'Bearer ' + token,
            'x-nonce-id': sessionStorage.getItem("adal.nonce.idtoken")
        },
        cache: "no-cache",
        body: optsToUse.method === 'POST' ? JSON.stringify(optsToUse.data) : null
    };
}

function getTokenHttpForFileDataObj(optsToUse, token) {
    return {
        method: optsToUse.method,
        headers: {
            'Origin': '',
            'Authorization': 'Bearer ' + token,
            'x-nonce-id': sessionStorage.getItem("adal.nonce.idtoken")
        },
        cache: "no-cache",
        body: optsToUse.data
    };
}

function getHttpObj(optsToUse) {
    return {
        method: optsToUse.method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Origin': ''
        },
        cache: "no-cache",
        body: optsToUse.method === 'POST' ? JSON.stringify(optsToUse.data) : null
    };
}

function fetchApi(baseApiUrl, optsToUse, obj, reject, resolve, progressInterval, progressNotification) {
    fetch(baseApiUrl + optsToUse.url, obj)
        .then(function (response) {
            if (response.status !== 200) {
                if (response.status != 400) {
                    if (optsToUse.httpErrorNotification === true) {
                        Notifications.error('Invalid response code - ' + response.status, optsToUse);
                    }
                    else if (optsToUse.httpErrorNotification !== false && optsToUse.httpErrorNotification !== true) {
                        Notifications.error(optsToUse.httpErrorNotification, optsToUse);
                    }
                }
                //might be better to do a common response here showing an error
                console.log(response.status);

                if (response.status == 400) {
                    response.json().then(function (data) {
                        if (data.errors) {

                            const propertyKeys = Object.keys(data.errors);

                            console.log(propertyKeys);

                            for (let i = 0; i < propertyKeys.length; i++) {
                                for (let ii = 0; ii < data.errors[propertyKeys[i]].length; ii++) {
                                    Notifications.error(`${propertyKeys[i]} - ${data.errors[propertyKeys[i]]}`, optsToUse);
                                }
                            }
                        }
                        reject('Invalid response code');
                    });
                } else if (response.status == 401) {
                    reject('Unauthorized');
                } else {
                    reject('Invalid response code');
                }
            }
            else {
                // Examine the text in the response
                response.json().then(function (data) {
                    if (data.success === true) {
                        if (optsToUse.successNotification !== false && typeof optsToUse.successNotification === 'string') {
                            Notifications.success(optsToUse.successNotification, optsToUse);
                        }
                    }
                    else {
                        if (optsToUse.errorNotification === true) {
                            Notifications.error(data.msg, optsToUse);
                        }
                        else if (optsToUse.errorNotification !== false && optsToUse.errorNotification !== true) {
                            Notifications.error(optsToUse.errorNotification, optsToUse);
                        }
                        reject(data.msg);
                    }
                    resolve(data);
                });
            }
        })
        .catch(function (err) {
            //might be better to do a common response here showing an error
            if (optsToUse.httpErrorNotification === true) {
                Notifications.error('Fetch Error :' + err, optsToUse);
            }
            else if (optsToUse.httpErrorNotification !== false && optsToUse.httpErrorNotification !== true) {
                Notifications.error(optsToUse.httpErrorNotification, optsToUse);
            }
            console.log('Fetch Error :-S', err);
            reject('Fetch Error :-S', err);
        })
        .finally(() => {
            if (optsToUse.progressMessage !== false && typeof optsToUse.progressMessage == 'string') {
                clearInterval(progressInterval);
                progressNotification.close();
            }
        });
}


